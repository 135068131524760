import './App.css';
import logo from './images/logo_negro.png'; 
import mosaico from './images/mosaico.png'; 
import mosaico2 from './images/mosaico2.png';
import mosaico3 from './images/mosaico3.png';
import mosaico4 from './images/mosaico4.png';
import mosaico5 from './images/mosaico5.png';
import image from  './images/image.jpg'
import location from  './images/huevos.jpg'
import AOS from 'aos';
import {Helmet} from "react-helmet";
import 'aos/dist/aos.css';

function App() {
  AOS.init();
  return (
    <>
    <Helmet 
      title="Cien Manteles"
      meta={[
        { name: "description", content: "Disfruta desayunos y comidas mexicanas con recetas tradicionales en Cien Manteles, donde la tradición se saborea en cada bocado." },
        { property: "og:type", content: "website" },
        { property: "og:title", content: "Cien Manteles - Desayunos y Comidas Tradicionales" },
        { property: "og:image", content: "https://cienmanteles.com/static/media/image.aaf5cf92f7e489e3e2bb.jpg" },
        { property: "og:url", content: "https://cienmanteles.com" },
        { property: "og:description", content: "En Cien Manteles, la tradición se saborea en cada bocado. Descubre nuestros desayunos y comidas mexicanas con recetas heredadas de generación en generación." }
      ]}
    />
    <div className='container'>
      <div className='first-section'>
        <div className='superior-bar'>
          <img src={mosaico} alt="" />
          <img src={mosaico2} alt="" />
          <img src={mosaico3} alt="" />
        </div>
        <img src={logo} alt="" />
        <ul className='navbar'>
          <li className='item'><a href="#nosotros">Nosotros</a></li>
          <li className='item'><a href="https://drive.google.com/file/d/1yevF5p4QOe7W1LIc9i6naiaX0qnXMi8R/view">Menú</a></li>
          <li className='item'><a href="https://wa.link/fswxmu">Reservar</a></li>
        </ul>
        <a href="#nosotros" className="scroll-down" address="true"></a>
      </div>
      <div className="second-section" id="nosotros">
        <h3 className='top-text' data-aos="fade-up">Desayunos y comidas tradicionales.</h3>
        <img src={image} alt="" data-aos="fade-up"/>
        <h3 className='bottom-text' data-aos="fade-up">En Cien Manteles, la tradición se saborea.</h3>
      </div>
      <div className='divisor'>
        <img src={mosaico} alt="" data-aos="fade-right"/>
        <img src={mosaico2} alt="" data-aos="fade-right" data-aos-delay="150"/>
        <img src={mosaico3} alt="" data-aos="fade-right" data-aos-delay="300"/>
        <img src={mosaico4} alt="" data-aos="fade-right" data-aos-delay="450"/>
        <img src={mosaico5} alt="" data-aos="fade-right" data-aos-delay="600"/>
      </div>
      <div className="third-section">
        <div className='overlay'></div>
        <h4>Conoce nuestro menú</h4>
        <a href="https://drive.google.com/file/d/1yevF5p4QOe7W1LIc9i6naiaX0qnXMi8R/view" className='view-menu'>Ver menú</a>
      </div>
      <div className="maps-section">
        <div className='maps-image'>
          <img src={location} alt="" data-aos="zoom-in-right"/>
        </div>
        <div className='maps-content'data-aos="zoom-in-right">
          <h4>Ubicación</h4>
          <p>Encuéntranos en una de las zonas más acogedores de la ciudad, sobre la avenida Cto. Colonias #614 B.<br></br>Nuestro restaurante está estratégicamente ubicado para ofrecerte un ambiente cálido y acogedor donde podrás disfrutar de los sabores auténticos de la cocina mexicana y yucateca.<br></br>¡Te esperamos con los brazos abiertos para que vivas una experiencia culinaria única!</p>
          <a href="https://maps.app.goo.gl/d2yoxvmzgju7soe18" className='view-menu'>¡Ven a visitarnos!</a>
        </div>
      </div>
      <footer>
        <div className="footer">
        <div className="row">
          <a href="https://www.instagram.com/100manteles/"><i className="fa fa-instagram"></i></a>
        </div>

        <div className="row">
        <ul>
          <li><a href="https://drive.google.com/file/d/1yevF5p4QOe7W1LIc9i6naiaX0qnXMi8R/view">Menú</a></li>
          <li><a href="https://wa.link/fswxmu">Reservar</a></li>
          <li><a href="/politicas">Políticas de privacidad</a></li>
        </ul>
        </div>

        <div className="row">
          Cien Manteles Copyright © 2024 <a href="https://devindev.com">Devindev</a> - All rights reserved
        </div>
        </div>
      </footer>
    </div>
    </>
  );
}

export default App;
